import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";

const data = {
  title: "Frequently ask question ",
  rows: [
    {
      title: 'What is "Doctor at Home" and how does it work?',
      content: `"Doctor at Home" is a personalized healthcare service where experienced doctors visit you at your home, offering consultations, diagnoses, and treatments without the need for you to travel. This convenient service ensures you receive professional medical care with the utmost comfort and privacy.`,
    },
    {
      title:
        "Can I book nursing or GDA services at my home ?",
      content: `Yes, we provide easy booking for professional nurses and General Duty Assistants (GDAs) to deliver compassionate care at your home. Whether it's post-operative care, elderly care, or routine health check-ups, our "Nurses at Home" service ensures you get the support you need, whenever you need it
        `,
    },
    {
      title: "Are home laboratory services available through your platform?",
      content: `Absolutely! We offer comprehensive "Laboratory at Home" services, allowing you to complete necessary medical tests without stepping out of your house. Our certified lab technicians ensure accurate and timely results for a wide range of tests, directly accessible through our app.`,
    },
    {
      title: "How can I rent medical devices for home use?",
      content: `Our platform makes it easy to rent a variety of medical devices, including ventilators, X-ray machines, and other essential equipment. "Medical Devices on Rent" service provides affordable access to high-quality equipment, ensuring you or your loved ones receive the best care at home.`,
    },
    {
      title: "What are the benefits of choosing us for home healthcare services?",
      content: `By choosing us for your "Home Healthcare Services," you benefit from a seamless experience that saves you time and money. Enjoy 1/3rd the cost compared to traditional hospital stays, access to only the necessary medicines, and a wide range of services from doctors to laboratory tests—all managed through a single, convenient platform.`,
    },
  ],
};

const styles = {
  backgroundColor: "F6F8FF",
  titleTextColor: "black",
  rowTitleColor: "black",
  rowContentColor: "grey",
  arrowColor: "grey",
  margin: "10px",
};

const config = {
  animate: true,
  arrowIcon: ">",
  tabFocus: true,
};

const divStyle = {
  width: "50%",
  margin: "auto",
  fontFamily: "Arial",
  padding: "5%",
};

export default function App() {
  return (
    <div style={divStyle} className="col-md-3 col col-6 ">
      <Faq data={data} styles={styles} config={config} />
    </div>
  );
}
