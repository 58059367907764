import React from "react";
import Doctor from "../Assets/doctor-group.png";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
        <img src={Doctor} alt="Doctor Group" className="about-image1" />
      </div>
      <div className="about-text-content">
        <h3 className="about-title">
          <span>About Us</span>
        </h3>
        <p className="about-description">
          Welcome to Doctor@Home by Indrashila Hospitals, where we're
          revolutionizing the way you manage healthcare from the comfort of your
          home. Our upcoming app simplifies your hospital needs into a single
          step, offering a wide range of at-home medical services. From doctor
          consultations, nursing care, and X-rays to ventilator support and
          laboratory services, we bring the hospital to you. Plus, our B2B
          solutions cater to professionals seeking medical staffing and
          equipment rentals. With us, you'll enjoy significant savings,
          convenience, and care tailored to your unique needs—all at your
          fingertips."
        </p>

        <h4 className="about-text-title">Your Solutions</h4>

        <SolutionStep
          title="Doctor at Your Doorstep"
          description="Contact us and our expert doctors at Indrashila Hospitals will be at your doorstep within an hour. Patient is securely handled, ensuring avilablity of equipments at your doorstep."
        />

        <SolutionStep
          title="Medical Test at Your Doorstep"
          description="Get any of your medical checkup done at your doorstep by Indrashila Hospitals. Services including Lab, X-ray, Ventilator, ECG, and many more."
        />

        <SolutionStep
          title="Medical Devices on Rent"
          description="Rent medical devices like wheelchairs, oxygen cylinders, and more from Indrashila Hospitals. We provide the best quality medical devices at your doorstep."
        />
      </div>
    </div>
  );
}

export default About;
