import React, { useEffect, useState } from "react";
import Doctor from "../Assets/doctor-picture.png";
import wa from "../Assets/wA.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faMagnifyingGlassChart,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "../Styles/Hero.css";

function Hero() {
  const navigate = useNavigate();
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBookAppointmentClick = () => {
    navigate("/appointment");
  };
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [service, setService] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the form submission here. For example, send data to an API or output it in the console.
    // console.log({ name, phone, city });
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  return (
    <div className="section-container">
      <div className="hero-section">
        <div className="text-section">
          <p className="text-headline">❤️ Doctor at Home</p>
          <h2 className="text-title">
            The Best Reliable Doctor Service At Your Doorstep
          </h2>
          <p className="text-descritpion">
            Revolutionizing Healthcare by Intelligent Patient Data Management
            System with GenAI.
          </p>
          <a href="#footer-section">
            <button className="text-appointment-btn" type="button">
              <FontAwesomeIcon icon={faMagnifyingGlassChart} /> Analyse
            </button>
          </a>
        </div>
        <div>
          <form
            onSubmit={handleSubmit}
            className=" flex text-center align-items-center"
            style={{
              padding: "1em",
              borderRadius: "10px",
              backgroundColor: "white",
              textAlign: "center",
            }}
          >
            <div style={{ marginBottom: "30px" }}>
              {" "}
              <h2 className="  text-warning "> Get a Call </h2>{" "}
            </div>
            <div style={{ marginBottom: "1em" }}>
              <input
                type="text"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{
                  background: "transparent",
                  border: "0",
                  borderBottom: "1px solid black",
                  boxSizing: "border-box",
                  color: "gray",
                  display: "block",
                  marginBottom: "30px",
                  outline: "none !important",
                  opacity: "0.6",
                  //  width: '100%',
                  padding: "0.5em",
                }}
              />
            </div>
            <div style={{ marginBottom: "1em" }}>
              <input
                type="tel"
                placeholder="Your Phone No."
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                style={{
                  background: "transparent",
                  border: "0",
                  borderBottom: "1px solid black",
                  boxSizing: "border-box",
                  color: "gray",
                  display: "block",
                  marginBottom: "30px",
                  outline: "none !important",
                  opacity: "0.6",
                  //  width: '100%',
                  padding: "0.5em",
                }}
              />
            </div>
            <div style={{ marginBottom: "1em" }}>
              <input
                type="text"
                placeholder="Your city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                style={{
                  background: "transparent",
                  border: "0",
                  borderBottom: "1px solid black",
                  boxSizing: "border-box",
                  color: "gray",
                  display: "block",
                  marginBottom: "30px",
                  outline: "none !important",
                  opacity: "0.6",
                  //  width: '100%',
                  padding: "0.5em",
                }}
              />
            </div>
            <div style={{ marginBottom: "1em" }}>
              <input
                type="text"
                placeholder="Describe your problem"
                value={service}
                onChange={(e) => setService(e.target.value)}
                style={{
                  background: "transparent",
                  border: "0",
                  borderBottom: "1px solid black",
                  boxSizing: "border-box",
                  color: "gray",
                  display: "block",
                  marginBottom: "30px",
                  outline: "none !important",
                  opacity: "0.6",
                  //  width: '100%',
                  padding: "0.5em",
                }}
              />
            </div>
            <button
              type="submit"
              style={{
                // width: '100%',
                padding: "0.5em",
                backgroundColor: "#008cba",
                color: "white",
                border: "none",
                borderRadius: "4px",
              }}
            >
              Enquire
            </button>
          </form>
        </div>
        <div className="hero-image-section">
          <img className="hero-image1" src={Doctor} alt="Doctor" />
        </div>
      </div>

      <div
        onClick={scrollToTop}
        className={`scroll-up ${goUp ? "show-scroll" : ""}`}
      >
        <FontAwesomeIcon icon={faAngleUp} />
      </div>

      <div className={`scroll-up2 ${goUp ? "show-scroll" : ""}`}>
        <a href="https://wa.me/919717244318">
          <img className="hero-image1" src={wa} alt="Doctor" />
        </a>
      </div>
    </div>
  );
}

export default Hero;
