import React from "react";
import "../Styles/Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer-section" id='footer-section'>
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <p className="ft-title">
              Doctor@<span className="ft-sign">Home</span>
            </p>
            <p className="ft-description">
                Indrashila Hospital, <br/> 
                Opposite Vega school <br/> 
                Fazilpur Road Sector 48 <br/> 
                Gurugram Haryana
            </p>
          </div>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Services</p>
          <ul className="ft-list-items">
            <li>
              <a href="#services">Doctor at Your Doorstep</a>
            </li>
            <li>
              <a href="#services">Medical Test at Your Doorstep</a>
            </li>
            <li>
              <a href="#services">Medical Devices on Rent</a>
            </li>
            <li>
              <a href="#services">Nursing Staff For Your Hospital</a>
            </li>
          </ul>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Legal</p>
          <ul className="ft-list-items">
            <li>
              <Link to={"/legal"}>General Info</Link>
            </li>
            <li>
              <Link to={"/legal"}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={"/legal"}>Terms of Services</Link>
            </li>
            <li>
              <Link to={"/legal"}>Consultations</Link>
            </li>
            <li>
              <Link to={"/legal"}>How it Works</Link>
            </li>
          </ul>
        </div>

        <div className="ft-list" id="contact">
          <p className="ft-list-title">Talk To Us</p>
          <ul className="ft-list-items">
            <li>
              <a href="mailto:indrashilahospital@gmail.com">indrashilahospital@gmail.com</a>
            </li>
            <li>
              <a href="tel:+918368458311">+91 8368458311</a>
            </li>
            <li>
              <a href="tel:+919717244318">+91 9717244318</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="ft-copyright">
        <p>© 2024 Doctor@Home. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
