export const customerReviews = [
  {
    name: "Krishna Jain",
    location: "DBA at wipro",
    message:
    "My experience visiting Indrashila Hospital was exceptional From the moment I walked in, I was greeted with warmth and professionalism. The staff was courteous and attentive, making me feel comfortable and at ease.."
   },
  {
    name: "Isha Biradar",
    
    message:
      "My visit to Indrashila Hospital left me feeling empowered and in control of my diabetes. I left with a clear plan, a support system, and the knowledge I needed to manage my condition effectively. I highly recommend this hospital to anyone seeking diabetes care and support.",
  },
  
  {
    name: "Pinky Yadav",
    location: "Interior Designer",
    message:
      "As someone dealing with orthopedic issues, I was naturally anxious, but the expertise of the orthopedic team quickly put me at ease.The doctors were not only highly skilled but also extremely patient in listening to my concerns and explaining the treatment options in detail.",
  },
  
  {
    name: "Rahul Taneja",
    location: "Own Business",
    message:
      "The dermatologist who treated me was not only knowledgeable but also took the time to explain my condition in simple terms. They discussed the treatment plan thoroughly, addressing my concerns and ensuring that I understood the prescribed medications..",
  },
  
  {
    name: "Dennis Green",
    location: "System Analytics",
    message:
      "The staff at Indrashila Hospital were not only courteous but also highly efficient. The waiting area was clean, and I didn't have to wait long before being called in to see the general physician..",
  },
  
  
];
