import React from "react";
import Navbar from "../Components/Navbar";
import Hero from "../Components/Hero";
// import EnquiryForm from "../Components/EnquiryForm";
import Info from "../Components/Info";
import About from "../Components/About";
import BookAppointment from "../Components/BookAppointment";
import Reviews from "../Components/Reviews";
import Faq from "../Components/Faq";
import Doctors from "../Components/DoctorCard";
import Map from "../Components/Map";
import Footer from "../Components/Footer";

function Home() {
  return (
    <div className="home-section">
      <Navbar />
      <Hero />
      {/* <EnquiryForm/> */}
      <Info />
      <About />
      <BookAppointment />
      <Reviews />
      <Map/>
      <Faq/>
      {/* <Doctors /> */}
      <Footer />
    </div>
  );
}

export default Home;
