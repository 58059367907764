import React from "react";
import InformationCard from "./InformationCard";
import {
  faHeartPulse,
  faHospitalUser,
  faComments,
  faRobot,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";

function Info() {
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>Services</span>
        </h3>
        <p className="info-description">
        Experience personalized healthcare at home with our comprehensive services: Doctor visits, nursing, X-rays, ventilators, and lab tests, all at your doorstep. Enjoy cost-effective solutions, saving up to 1/3 compared to hospital stays, with treatments tailored to your needs. Our B2B offerings include medical staffing and equipment rentals.
        </p>
      </div>

      <div className="info-cards-content">
        <InformationCard
          title="Doctor at Your Doorstep"
          description="Contact us and our expert doctors at Indrashila Hospitals will be at your doorstep within an hour. Patient is securely handled, ensuring avilablity of equipments at your doorstep."
          icon={faHeartPulse}
        />

        <InformationCard
          title="Medical Test at Your Doorstep"
          description="Get any of your medical checkup done at your doorstep by Indrashila Hospitals. Services including Lab, X-ray, Ventilator, ECG, and many more."
          icon={faHospitalUser}
        />

        <InformationCard
          title="Medical Devices on Rent"
          description="Rent medical devices like wheelchairs, oxygen cylinders, and more from Indrashila Hospitals. We provide the best quality medical devices at your doorstep."
          icon={faComments}
        />

        <InformationCard
          title="Nursing Staff For Your Hospital"
          description="Get access to Network of best Nursing Staff for your Hospital. We provide the best quality medical devices at your doorstep."
          icon={faRobot}
        />
      </div>
    </div>
  );
}

export default Info;
